<template>
    <main class="news-index-page">
        <section v-if="news.page.data.novelties.data.length" class="news-index-page-wrapper">
            <h1 class="news-index-page-wrapper__title">{{ $lang.pages.novedades.novelties }}</h1>
            <ul>
                <li v-for="(item, index) in news.page.data.novelties.data" :key="index">
                    <CardNews :data="item" />
                </li>
            </ul>
            <ButtonLoadMore
                v-if="pagination.show"
                :loading="pagination.loading"
                :handler="loadMoreNews"
                padding="pb-0"
            />
        </section>
        <MiscNoResults v-else mode="megaphone" :title="$lang.pages.novedades.no_novelties" />
    </main>
</template>

<script lang="ts" setup>
import type { Api } from '~~/global'

const { buildHeaders, baseURL, endpoints } = useApiConfig()

const getNovelties = (page?: number) =>
    $fetch<Api.Responses.Pages.Novelties>(endpoints.pages.novelties.path, {
        headers: buildHeaders(),
        baseURL,
        method: 'GET',
        params: {
            page,
        },
    })
const { data: responseData, error } = await useAsyncData('novelties-page-data', () =>
    getNovelties().catch((e) => e.data),
)

if (error.value || !responseData.value || responseData.value.error) {
    throw createError({
        statusCode: responseData.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            responseData.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}

const news = ref(responseData.value)

const pagination = reactive({
    loading: false,
    show: true,
})

const Route = useRoute()
const {
    public: { origin },
} = useRuntimeConfig()

useSeoMeta({
    title: news.value.page.title,
    ogUrl: origin + Route.path,
    ogTitle: news.value.page.title,
    description: news.value.page.description,
    ogDescription: news.value.page.description,
    ogImageAlt: news.value.page.title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})

const loadMoreNews = () => {
    pagination.loading = true
    const newNews = JSON.parse(JSON.stringify(news.value)) as Api.Responses.Pages.Novelties
    const page = newNews.page.data.novelties.meta.current_page + 1

    getNovelties(page)
        .then((res: Api.Responses.Pages.Novelties) => {
            const { meta, links, data } = res.page.data.novelties
            newNews.page.data.novelties.data.push(...data)
            newNews.page.data.novelties.meta = meta
            newNews.page.data.novelties.links = links

            news.value = newNews

            pagination.loading = false
            pagination.show = page < news.value.page.data.novelties.meta.last_page
        })
        .catch((err: any) => {
            console.error('error', err)
            pagination.loading = false
        })
}
</script>

<style lang="postcss" scoped>
.news-index-page {
    @apply container mb-10 mt-6;
    &-wrapper {
        @apply mx-auto max-w-4xl space-y-8 rounded-xl p-3 sm:bg-white;
        &__title {
            @apply text-[28px] font-medium lg:text-[32px];
        }
        ul {
            @apply space-y-4;
        }
        li:not(:last-of-type) {
            @apply border-b pb-4;
        }
    }
}
</style>
